<template>
  <div v-if="sector">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title v-if="sector.id">Editing {{ translate(sector.name) }}</v-card-title>
          <v-card-title v-else>Creating new sector</v-card-title>
          <v-card-text>
            <m-field v-model="sector.name" m="sector" a="name" i18n />
            <m-field v-model="sector.domain" m="sector" a="domain" component="v-select" :items="['SP', 'SS', 'ST']" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" color="primary">{{$t('actions.save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import ImageUploader from 'vue-image-upload-resize';
import formatter from "@/mixins/formatter";

@Component({components: {ImageUploader}, mixins: [formatter]})
export default class SectorForm extends Vue {

  @Prop() sectorId;

  sector = null;

  mounted() {
    // get or create sector object
    if (this.sectorId) {
      this.$api.get(`sector/${this.sectorId}`).then(j => {
        this.sector = j.data;
      });
    } else {
      this.sector = {
        name: {},
        domain: '',
      }
    }
  }

  onSubmit() {
    const call = this.sectorId
      ? this.$api.patch(`sector/${this.sectorId}`, this.sector)
      : this.$api.post(`sector`, this.sector)

    call.then(j => {
      this.$emit('save', j.data);
    }).catch(err => {
      if (err.response) this.$refs.form.setErrors(err.response.data);
      this.$snack.validationError(err.response.data)
    });
  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>

<style>
#fileInput {
  display: none;
}
h2 {
  margin: 1em 0;
}
</style>
