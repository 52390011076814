<template>
  <v-container fluid>

    <card :title="$t('sector.title')">
      <template v-slot:actions>
        <v-btn small color="primary" @click="createSector()">
          <v-icon left small>fa fa-plus</v-icon>
          {{ $t('actions.create') }}
        </v-btn>
      </template>
      <!-- table -->
      <grid-view ref="grid" :headers="headers" endpoint="sector" model="sector">
        <template v-slot:item._actions="{ item }">
          <v-btn @click="viewSector(item)" icon small>
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn @click="editSector(item)" icon small>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteSector(item)" icon small>
            <v-icon x-small>fa fa-trash</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.name="{ item }">
          {{ translate(item.name) }}
        </template>
      </grid-view>
    </card>


    <!-- view dialog -->

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="600">
      <Form :sectorId="editedSectorId" v-if="editDialog" @save="onDialogSave"/>
    </v-dialog>

  </v-container>
</template>

<script>
import {Vue, Component} from "vue-property-decorator";
import Form from "@/pages/sector/Form";
import formatter from '@/mixins/formatter';
import GridView from "@/components/GridView";
import Card from "@/components/Card";
import {setPageTitle} from "@/utils/meta";

@Component({components: {Card, GridView, Form}, mixins: [formatter]})
export default class SectorIndex extends Vue {

  viewDialog = false;
  editDialog = false;
  sector = null;
  editedSectorId = null;

  created() {
    setPageTitle(this.$i18n.t('models.sector.selves'));
  }

  get headers() {
    return [
      {text: '', value: '_actions',},
      {text: this.$i18n.t('models.sector.domain'), value: 'domain', width: 1},
      {text: this.$i18n.t('models.sector.self'), value: 'name', sort: this.i18nSort},
    ];
  }

  viewSector(sector) {
    this.$api.get(`sector/${sector.id}`).then(res => {
      this.sector = res.data;
      this.viewDialog = true;
    });
  }

  createSector() {
    this.editedSectorId = null;
    this.editDialog = true;
  }

  editSector(sector) {
    this.editedSectorId = sector.id;
    this.editDialog = true;
  }

  deleteSector(sector) {
    this.$root.$confirm.open('Delete sector', 'Are you sure you want to delete this sector ?', {}).then((confirm) => {
      if (confirm) {
        this.$api.delete(`sectors/${sector.id}`).then(() => {
          this.$refs.grid.getModels();
        }).catch(() => {
          alert("Unable to remove sector");
        });
      }
    });
  }

  onDialogSave(sector) {
    this.$refs.grid.getModels();
    this.editDialog = false;
    this.editedSectorId = null;
  }

}
</script>
